import axios from 'axios';
import authConfig from "../environment/config";
import { appConst } from "../constant/constant";
import CryptoJS from 'crypto-js';
import { AppType } from "../constant/appType";

export const msalToken = "msal.idtoken";
export const FORGOTPASSWORDAPI ='/api/utility/ForgotPassword'
export const USEREXISTSAPI ='/api/User/GetADUser'
export const GETUNIFYID ='/api/utility/GetUnifyId'
export const GETUNIFYIDSIGNEDTOKEN ='/api/utility/GetUnifyIdSignedToken'
export const UNLOCKUSER ='/api/utility/SignInAttempts'

export const api = {
  encryption: {
  IV: "8080808080808080",
  Key: "8080808080808080"
}}

export const clearUMCache = () => {
    const cacheKeys = ["msal.idtoken", "apiUrl", "uiUrl", "redirectAppUri", "updateSignIn", "applicationConfig", "usertype"];
    cacheKeys.forEach(key => {
      localStorage.removeItem(key);
    });
};

// Get template data 
export const GetAppTempleateData = (appConfig, callback) => {
  let baseUrl = `${authConfig.storageUri}apps/`;
  if(appConfig && appConfig.appClientId && appConfig.appClientId !== 'null' && appConfig.appClientId != appConst.EmptyGuid){
    baseUrl = baseUrl + appConfig.appClientId + '/app-template.json?v=';
    axios.get(baseUrl + Math.floor(Math.random() * 100))
    .then(function (response) {
      callback(response.data);
    })
    .catch(error => {
      console.log('Details: ', error);
      callback(null);
    });
  } else {
    //baseUrl = baseUrl + 'app-template.json?v=';
    callback(null);
  }
};


//Get all countries
export const GetAllCountries =  async() => {  
  return await axios.get(`https://countriesnow.space/api/v0.1/countries`);
};

export const GetCountryStates = async(obj, callback) => {
  // Get all country's states
  await axios.post(`https://countriesnow.space/api/v0.1/countries/states`,  obj)
  .then(res => {
    callback(res.data);
  });
};

export const GetStateCities = async(obj, callback) => {  
  // Get all states's cities
 await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, obj)
  .then(res => {
    if(res && res.data && res.data.data.length == 0){
      res.data.data = ["Other"];
    }      
    callback(res.data);
  })
  .catch(error => {
    console.error('Error occurred', error);
  })
};

export const ForgotPasswordApiCall = async(config, obj, callback) => {
  const baseApi = (config.apiUrl && config.apiUrl.indexOf("qa") !== -1)?  authConfig.api.qa : authConfig.api.development;
  await axios.post(`${baseApi}${FORGOTPASSWORDAPI}`, obj, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(res => {
    callback(res.data);
  })
};

export const IsUserExists = async(email, userType = 1, callback) => {
  const apiUrl = authConfig.api.development + USEREXISTSAPI + `?email=${email}&userType=${userType}`; //(config.apiUrl.indexOf("qa") !== -1)?  authConfig.api.qa : authConfig.api.development;
  axios.get(apiUrl)
  .then(function (response) {
    callback(response.data);
  })
  .catch(error => {
    console.log('Details: ', error);
    callback(null);
  });
};

export const GetUnifyId = async(config, email, callback)  => { 
  const baseApi = (config.apiUrl && config.apiUrl.indexOf("qa") !== -1)?  authConfig.api.qa : authConfig.api.development;
  await axios.post(`${baseApi}${GETUNIFYID}?email=${email}`, null,
  {
   headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/json',
     'Authorization': 'Carr!er@pphub2024',
   }
   }).then(res => {
     callback(res.data);
   })
   .catch(error => {
     console.error('Error occurred', error);
   })
 };

export const UnlockAccount = async(email, code = '', isUnlock = false,appType='', callback) => {
  let apiUrl = authConfig.api.development + UNLOCKUSER 
  if(isUnlock)
    apiUrl += `?email=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}&IsAccountUnlock=${isUnlock}`; 
  else
    apiUrl += appType!=AppType.CSS? `?email=${email}`:`?email=${email}&appType=${appType}`;

  axios.post(apiUrl, null, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'apiKey': encryptData('Carr!er@pphub2024'),
    }
    }).then(function (response) {
    callback(response.data);
  }).catch(error => {
    console.log('Details: ', error);
    callback(null);
  });
};

 export const encryptData = (data) => {
  let key = CryptoJS.enc.Utf8.parse(api.encryption.Key);
  let iv = {
      keySize: 128 / 8,
      iv: CryptoJS.enc.Utf8.parse(api.encryption.IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  };
  return encodeURIComponent(CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, iv));
}
