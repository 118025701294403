import React, { useState } from "react";
import resertPasswordFormStyles from "./resetPasswordFormStyle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LogoForm from "../brandLogo/logoForm";

function ResetPasswordForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form,
        formfield, cancelBtn, normalBtn, formPanel, formFieldLabel, buttons, validError, divInput} = resertPasswordFormStyles();
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    function resetPassword() {
        const { password, confirmPassword } = props.formState;
        const verified = verification(password, confirmPassword);
        if (verified) {
            props.confirmPassword();
        }
    }
    
    function cancel() {
        //console.log("ResetPasswordcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    function verification(password, confirmPassword) {
        let isVerified = true;
        var regExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&])(?!.*[^a-zA-Z0-9!@#\$%&]).{8,64}$/);
        if (password == null || password == "") {
            setPasswordError("Password is required");
            isVerified = false;
        }else if(regExp.test(password) == false){
            setPasswordError(`Password must be between 8 and 64 characters.
                The password must have following:
                <div>&nbsp; - One lowercase letter</div>
                <div>&nbsp; - One uppercase letter</div>
                <div>&nbsp; - One digit</div>
                &nbsp;- One special character. Allowed special characters: !@#$%&`);
            isVerified = false;
        } else {
            setPasswordError("");
        }
        
        if (confirmPassword == null || confirmPassword == "") {
            setConfirmPasswordError("Confirm Password is required");
            isVerified = false;
        }
        else if (password != "" && confirmPassword != "" && password != confirmPassword) {
            setConfirmPasswordError("Password and Confirm Password must be same");
            isVerified = false;
        }
        else {
            setConfirmPasswordError("");
        }

        return isVerified;
    }

    function passwordchange(e) {
        var regix = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&])(?!.*[^a-zA-Z0-9!@#\$%&]).{8,64}$/);
        if (e.target.value == null || e.target.value == "") {
            setPasswordError("Password is required");
        } else if (regix.test(e.target.value) == false) {
            setPasswordError(`Password must be between 8 and 64 characters.
                The password must have following:
                <div>&nbsp; - One lowercase letter</div>
                <div>&nbsp; - One uppercase letter</div>
                <div>&nbsp; - One digit</div>
                &nbsp;- One special character. Allowed special characters: !@#$%&`);
        } else {
            setPasswordError("");
        }
        props.onChange(e);
    }

    function confirmPasswordchange(e) {
        if (e.target.value == null || e.target.value == "") {
            setConfirmPasswordError("Confirm Password is required");
        }
        else {
            setConfirmPasswordError("");
        }
        props.onChange(e);
    }

    return (
        <div className={rootsignIn}>
            <LogoForm appConfig={props.appConfig}/>
            <div className={card}>
                <div className={cardbody}>
                    <form noValidate autoComplete="off" className={form}>
                        <div className={formPanel} >
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Password
                                </div>
                                <div className={validError} dangerouslySetInnerHTML={{ __html: passwordError }} />
                                <input
                                    name="password"
                                    type="password"
                                    id="outlined-basic"
                                    onChange={passwordchange}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Confirm Password
                                </div>
                                <div className={validError}>
                                    {confirmPasswordError}
                                </div>
                                <input
                                    name="confirmPassword"
                                    type="password"
                                    id="outlined-basic"
                                    onChange={confirmPasswordchange}
                                    className={formfield}
                                />
                            </div>
                        </div>
                        <div className={buttons}>
                            <Button className={`${formfield} ${normalBtn}`} onClick={resetPassword}>
                                Continue
                            </Button>
                            <Button className={`${formfield} ${cancelBtn}`} onClick={cancel}>
                                CANCEL
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordForm;
