import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { countryList } from "../../../constant/constant";
import signUpFormStyles from "./signUpFormStyle";
import LogoForm from "../brandLogo/logoForm";
import { clearUMCache, GetCountryStates, GetStateCities } from '../../../js/utilities';

function SignUpForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form, select, formfield, cancelBtn,
        normalBtn, formPanel, formFieldLabel, buttons, validError, divInput, statusMsg} = signUpFormStyles();
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [contryError, setContryError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [countryName, setCountryName] = useState("");
    const [countryStatus, setCountryStatus] = useState(false);
    const [stateStatus, setStateStatus] = useState(false);
    //const [countryListObj, setCountryListObj] = useState([]);
    const [stateListObj, setStateListObj] = useState([{"name": "--State/Region--"}]);
    const [cityListObj, setCityListObj] = useState(["--City--"]);

    const GetCountryState =  async(country) => {
        try{
            setStateListObj([{"name": "Loading..."}]);
            props.formState["custom:state"] = "";
            props.formState["custom:city"] = "";
            setCountryStatus(true);            
            await GetCountryStates({"country": country}, function(response) {
                setStateListObj([{"name": "--State/Region--"}].concat(response.data.states));
                setCountryStatus(false);
                //setStateName(response.data.states[0].name);
                if(response.data.states[0].name != "Other"){
                    setCountryName(country);
                    //GetStateCity(response.data.states[0].name, country);
                }
            });
        }catch(error){
            console.log(error);
        }
    };

    const GetStateCity =  async(state, country) => {
        setCityListObj(["Loading..."]);
        props.formState["custom:city"] = "";
        setCountryStatus(true);
        setStateStatus(true);
        await GetStateCities({"country": (country == "" || typeof country == "undefined")? countryName : country, "state": state}, function(response) {           
            setCityListObj(["--City--"].concat(response.data));
            setCountryStatus(false);
            setStateStatus(false);
            //setCityName(response.data[1]);
        });
    };

    function signUp() {
        const { username, password, confirmPassword, given_name, family_name, 'custom:country': country , 'custom:state': state , 'custom:city': city } = props.formState;
        const verified = verification(password, confirmPassword, given_name, family_name, country, state, city);

        if (verified) {
            props.signUp();
        }
    }

    function cancel() {
        localStorage.clear();
        //clearUMCache();
        //console.log("SignUpcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    function verification(password, confirmPassword, given_name, family_name, country, state, city) {
        var regix = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&])(?!.*[^a-zA-Z0-9!@#\$%&]).{8,64}$/);
        let isVerified = true;
        if (password == null || password == "") {
            setPasswordError("Password is required");
            isVerified = false;
        } else if (regix.test(password) == false) {
            setPasswordError(`Password must be between 8 and 64 characters.
                The password must have following:
                <div>&nbsp; - One lowercase letter</div>
                <div>&nbsp; - One uppercase letter</div>
                <div>&nbsp; - One digit</div>
                &nbsp;- One special character. Allowed special characters: !@#$%&`);
            isVerified = false;
        } else {
            setPasswordError("");
        }

        if (confirmPassword == null || confirmPassword == "") {
            setConfirmPasswordError("Confirm Password is required");
            isVerified = false;
        } else if (password != "" && confirmPassword != "" && password != confirmPassword) {
            setConfirmPasswordError("Password and Confirmpassword must be same");
            isVerified = false;
        } else if (regix.test(confirmPassword) == false) {
            setConfirmPasswordError(`
                Password must be between 8 and 64 characters.
                The password must have following:
                 - One lowercase letter
                 - One uppercase letter
                 - One digit
                 - One special character. Allowed special characters: !@#$%&`);
            isVerified = false;
        } else {
            setConfirmPasswordError("");
        }

        if (family_name == null || family_name == "") {
            setLastNameError("Last Name is required");
            isVerified = false;
        } else {
            setLastNameError("");
        }

        if (given_name == null || given_name == "") {
            setFirstNameError("First Name is required");
            isVerified = false;
        } else {
            setFirstNameError("");
        }

        if (country == null || country == "") {
            setContryError("Country is required");
            isVerified = false;
        } else {
            setContryError("");
        }

        if (state == null || state == "" || state == "--State/Region--") {
            setStateError("State/Region is required");
            isVerified = false;
        } else {
            setStateError("");
        }

        if (city == null || city == "" || city == "--City--") {
            setCityError("City is required");
            isVerified = false;
        } else {
            setCityError("");
        }

        return isVerified;
    }

    function firstNamechange(e) {
        if (e.target.value == null || e.target.value == "") {
            setFirstNameError("First name is required");
        }
        else {
            setFirstNameError("");
        }
        props.onChange(e);
    }

    function lastNamechange(e) {
        if (e.target.value == null || e.target.value == "") {
            setLastNameError("Last name is required");
        }
        else {
            setLastNameError("");
        }
        props.onChange(e);
    }

    function passwordchange(e) {
        var regix = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&])(?!.*[^a-zA-Z0-9!@#\$%&]).{8,64}$/);
        if (e.target.value == null || e.target.value == "") {
            setPasswordError("Password is required");
        }
        else if (regix.test(e.target.value) == false) {
            setPasswordError(`Password must be between 8 and 64 characters.
                The password must have following:
                <div>&nbsp; - One lowercase letter</div>
                <div>&nbsp; - One uppercase letter</div>
                <div>&nbsp; - One digit</div>
                &nbsp;- One special character. Allowed special characters: !@#$%&`);
        }
        else {
            setPasswordError("");
        }
        props.onChange(e);
    }

    function confirmPasswordchange(e) {
        var regix = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&])(?!.*[^a-zA-Z0-9!@#\$%&]).{8,64}$/);
        if (e.target.value == null || e.target.value == "") {
            setConfirmPasswordError("Confirm Password is required");
        }
        else if (regix.test(e.target.value) == false) {
            setConfirmPasswordError(`Password must be between 8 and 64 characters.
                The password must have following:
                <div>&nbsp; - One lowercase letter</div>
                <div>&nbsp; - One uppercase letter</div>
                <div>&nbsp; - One digit</div>
                &nbsp;- One special character. Allowed special characters: !@#$%&`);
        }
        else {
            setConfirmPasswordError("");
        }
        props.onChange(e);
    }

    function countryNamechange(e) {
        setStateListObj([{"name": "--State/Region--"}]);
        setCityListObj(["--City--"]);

        if (e.target.value == null || e.target.value == "") {
            setContryError("Country is required");
        } else {
            setCountryName(e.target.value);
            GetCountryState(e.target.value);
            setContryError("");
        }
        props.onChange(e);
    }

    function stateNamechange(e) {
        if (e.target.value == null || e.target.value == "" || e.target.value == "--State/Region--") {
            setStateError("State/Region is required");
        } else {
            //setStateName(e.target.value);
            GetStateCity(e.target.value);
            setStateError("");
        }

        props.onChange(e);
    }

    function cityNamechange(e) {
        if (e.target.value == null || e.target.value == "" || e.target.value == "--City--") {
            setCityError("State is required");
        }
        else {
            //setCityName(e.target.value);
            setCityError("");
        }

        props.onChange(e);
    }

    return (
        <div className={rootsignIn}>
            <LogoForm appConfig={props.appConfig}/>
            <div className={card}>
                <div className={cardbody}>
                    <form noValidate autoComplete="off" className={form}>
                        <div className={formPanel} >
                            <div>
                                <div className={formFieldLabel}>
                                    Email Address
                                </div>
                                <input
                                    name="username"
                                    id="outlined-basic"
                                    value={props.userEmail}
                                    disabled={true}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    New Password
                                </div>
                                <div className={validError} dangerouslySetInnerHTML={{ __html: passwordError }} />
                                <input
                                    name="password"
                                    id="outlined-basic"
                                    type="password"
                                    onChange={passwordchange}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Confirm New Password
                                </div>
                                {/* <div className={validError}>
                                    {confirmPasswordError}
                                </div> */}
                                <div className={validError} dangerouslySetInnerHTML={{ __html: confirmPasswordError }} />
                                <input
                                    name="confirmPassword"
                                    id="outlined-basic"
                                    type="password"
                                    onChange={confirmPasswordchange}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    First Name
                                </div>
                                <div className={validError}>
                                    {firstNameError}
                                </div>
                                <input
                                    name="given_name"
                                    id="outlined-basic"
                                    onChange={firstNamechange}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Last Name
                                </div>
                                <div className={validError}>
                                    {lastNameError}
                                </div>
                                <input
                                    name="family_name"
                                    id="outlined-basic"
                                    onChange={lastNamechange}
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Country
                                </div>
                                <div className={validError}>
                                    {contryError}
                                </div>
                                <select name="custom:country" onChange={countryNamechange} className={select} disabled={countryStatus}>
                                    <option value="">--Country--</option>{
                                        countryList.map((obj) => {
                                            return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    State/Region
                                </div>
                                <div className={validError}>
                                    {stateError}
                                </div>
                                <select name="custom:state" onChange={stateNamechange} className={select} disabled={stateStatus}>
                                    {
                                        stateListObj.map((obj) => {
                                            return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    City
                                </div>
                                <div className={validError}>
                                    {cityError}
                                </div>
                                <select name="custom:city" onChange={cityNamechange} className={select}>  
                                    {
                                        cityListObj.map((obj) => {
                                            return <option key={obj} value={obj}>{obj}</option>
                                        })
                                    }     
                                </select>
                            </div>
                        </div>
                        <div className={statusMsg}>
                            {props.internalSignUpStatus}
                        </div>
                        <div className={buttons}>
                            <Button className={`${formfield} ${normalBtn}`} onClick={signUp}>
                                CREATE
                            </Button>
                            <Button className={`${formfield} ${cancelBtn}`} onClick={cancel}>
                                CANCEL
                            </Button>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignUpForm;
