import React, { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import config from "./aws-exports";
import NewSignInForm from "./components/signIn/newSignInForm";
import SignUpForm from "./components/signup/signUpForm";
import NewSignUpForm from "./components/signup/newSignUpForm";
import NewSocialSignUpForm from "./components/signup/newSocialSignUpForm";
import InternalSignUpForm from "./components/signup/internalSignUpForm";
import NewInternalSignUpForm from "./components/signup/newInternalSignUpForm";
import NewHvacSignUpForm from "./components/signup/newHvacSignUpForm";
import ResetPasswordForm from "./components/forgotPassword/resetPasswordForm"
import NewResetPasswordForm from "./components/forgotPassword/newResetPasswordForm"
import ForgotPasswordForm from "./components/forgotPassword/forgotPasswordForm";
import NewForgotPasswordForm from "./components/forgotPassword/newForgotPasswordForm"
import { msalToken, ForgotPasswordApiCall, GetAppTempleateData, IsUserExists, UnlockAccount } from "../js/utilities";
import { modifyToken, modifyTokenZendesk, getCurrentSessionIdToke, logout } from "./authModule";
// import jwt from "jsonwebtoken";
import envConfig from "../environment/config";
import { AppType } from "../constant/appType";
import { appConst } from "../constant/constant";
import CSSSignInForm from "./components/CSSsignIn/CSSsignInForm";
import CarrierAppLogo  from '../assets/images/carrier_header.png';
import CarrierBgImage  from '../assets/images/backgroundImage.png';
import { defaultTemplate } from '../constant/constant';
import * as jose from "jose";


const useStyles = makeStyles((theme) => ({
  root1: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      height: "100vh"
    },
    root: {
      height: "100%",
      width: "100%"
    },
    display: "inline-block",
    background: "#e0e8e8",
    padding: "60px 80px 60px 60px",
    "margin-top": "50px",
  },
  buttonstyle: {
    margin: "4px",
    width: "220px",
  },
  spanStyle: { 
    background:"#0033ab",
    color:"white",
    "font-family": "Roboto,Arial,Helvetica,sans-serif",
    "padding-left":"4px",
    "padding-right":"4px"
  }
}));

const LoginWrapper = (props) => {
  const initialFormState = {
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    authCode: "",
    given_name: "",
    family_name: "",
    formType: "loading",
    oid: "",
    "custom:country": "",
    "custom:state": "",
    "custom:city": "",
    confirmatinCode: ""
  };
  // const { decode } = jwt;
  const [showSignInForm, setShowSignInForm] = useState(false);
  const [formState, updateFormState] = useState(initialFormState);
  const [userEmail, setUserEmail] = useState("");
  const [signInError, setSignInError] = useState("");
  const [signInUnReq, setSignInUnReq] = useState("");
  const [signInPwdReq, setSignInPwdReq] = useState("");
  const [forgotPassword, setForgotPassword] = useState("");
  const [forgotPwdStatus, setForgotPwdStatus] = useState("");
  const [signInStatus, setSignInStatus] = useState("");
  const [internalSignUpStatus, setInternalSignUpStatus] = useState("");
  const [appConfig, setAppConfig] = useState({});
  const [appTemplate, setAppTemplate] = useState({ backgroundImage : "" });
  const [appLogo, setAppLogo] = useState("");
  const [fontColor, setFontColor] = useState({});
  const [customTemplate, setCustomTemplate] = useState({});
  const [noOfAttempts, setNoOfAttempts] = useState(5);
  const classes = useStyles();

  const getConfigObject = (params) => {
    if (params.get('appConfig')) {
      let apiUrl = params.get('apiUrl')
      let domain = params.get('domain')
      let clientId = params.get('clientId')
      let identityPoolId = params.get('identityPoolId')
      let userPoolId = params.get('userPoolId')
      let uiUrl = params.get('uiUrl')
      let postLogOutUri = params.get('postLogOutUri')
      let appType = params.get('appType');
      let isLatestAuth = params.get('isLatestAuth');
      let appClientId = params.get('appClientId');

      const appConfig = {
        "apiUrl": apiUrl ? decodeURIComponent(apiUrl) : null,
        "uiUrl": uiUrl ? decodeURIComponent(uiUrl) : null,
        "userPoolId": userPoolId ? decodeURIComponent(userPoolId) : config.aws_user_pools_id,
        "identityPoolId": identityPoolId ? decodeURIComponent(identityPoolId) : config.aws_cognito_identity_pool_id,
        "clientId": clientId ? decodeURIComponent(clientId) : config.aws_user_pools_web_client_id,
        "domain": domain ? decodeURIComponent(domain) : config.oauth.domain,
        "postLogOutUri": postLogOutUri ? decodeURIComponent(postLogOutUri) : config.oauth.redirectSignIn,
        "appType": appType ? decodeURIComponent(appType) : null,
        "isLatestAuth": isLatestAuth ? decodeURIComponent(isLatestAuth) : null,
        "appClientId": decodeURIComponent(appClientId)
      }
      localStorage.setItem('applicationConfig', JSON.stringify(appConfig));
      return appConfig;
    }
    
    let applicationConfig = localStorage.getItem('applicationConfig');
    return (applicationConfig == null)? {} : JSON.parse(localStorage.getItem('applicationConfig'));
  }

  useEffect(() => {
    async function initialSetup() {
      const params = new URLSearchParams(window.location.search);
      let appConfigData = getConfigObject(params);
      setAppConfig(appConfigData);

      if(appConfig.appType != AppType.CSS){
        GetAppTempleateData(appConfigData, function(response) {      
          setCustomTemplate({
            appImage: (response == null || response.appImage == null || response.appImage == '') ? 
              CarrierAppLogo  : response.appImage,
            bgImage: { backgroundImage: (response == null || response.bgImage == null || response.bgImage == '') ?
              `url(${CarrierBgImage})` : `url(${response.bgImage})` },
            fontColor:{ color : (response == null || response.color == null || response.color == '' || response.color == 'string') ?
              `${defaultTemplate.color}` : `${response.color}`}
          });
        });
      }

      let isLogout = params.get('isLogout')
      if (isLogout) {
        updateFormState(() => ({ ...formState, formType: "loading" }));
        logout(appConfigData)
      }

      // let isRefreshToken = params.get('refreshToken')
      // if (isRefreshToken) {
      //   refreshSession(appConfigData)
      // }

      const newConfig = {
        ...config,
        'aws_user_pools_id': appConfigData.userPoolId || config.aws_user_pools_id,
        'aws_cognito_identity_pool_id': appConfigData.identityPoolId || config.aws_cognito_identity_pool_id,
        'aws_user_pools_web_client_id': appConfigData.clientId || config.aws_user_pools_web_client_id,
        // 'oauth': {
        //   ...config.oauth,
        //   "domain": appConfigData.domain || config.oauth.domain
        // }
        Auth: {
          ...config.oauth,
          "domain": appConfigData.domain || config.oauth.domain
        }
      };

      Amplify.configure(newConfig);   

      let mailaddress = params.get('emailAddress');
      if (mailaddress) {
        setUserEmail(mailaddress);
        updateFormState(() => ({ ...formState, username: mailaddress }));
      }
      
      let isSignUp = params.get('isSignUp');    
      let resetPassword = params.get('reset-password');
      let accountUnlock = params.get('account-unlock');

      if (isSignUp) {
        updateFormState(() => ({ ...formState, formType: "signUp" }));
      } else if (resetPassword) {
        let confirmatinCode = params.get('confirmation_code');
        let username = params.get('user_name');
        updateFormState(() => ({ ...formState, username: username, confirmatinCode: confirmatinCode, formType: "resetPassword" }));
      } else if (accountUnlock) {
        let confirmatinCode = params.get('confirmation_code');
        if(confirmatinCode != null && confirmatinCode != undefined && confirmatinCode != ''){
          //Unlock the account
          await UnlockAccount(params.get('email_address'), confirmatinCode, true,"", async function(response) {
            const appConfigData = localStorage.getItem('applicationConfig') == null ? {} : JSON.parse(localStorage.getItem('applicationConfig'));
            window.location.href = `${window.location.origin}?apiUrl=${encodeURIComponent(appConfigData.apiUrl || envConfig.api.development)}&uiUrl=${encodeURIComponent(appConfigData.uiUrl || envConfig.ui.development)}&redirectAppUri=${encodeURIComponent(window.location.origin)}&appType=${appConfigData.appType}`;
          });
        }else{
          login();
        }
      } else {
          login();
      }
    }    

    initialSetup();
    setAuthListener();
  }, []);

  const setAuthListener = async () => {
    Hub.listen("auth", async (data) => {
      const appConfigData = JSON.parse(localStorage.getItem('applicationConfig')); // use appConfig from here for api call
      switch (data.payload.event) {
        case "signOut":
          updateFormState(() => ({ ...formState, formType: "signIn" }));
          break;
        case "oAuthSignOut":
          updateFormState(() => ({ ...formState, formType: "signIn" }));
          break;
        case "signIn":
          updateFormState(() => ({ ...formState, formType: "loading" }));
          let currentToken = await getUserToken();
          let mToken = await modifyTokenCall(appConfigData,currentToken)
           localStorage.setItem(msalToken, mToken);
          const { country, given_name, family_name, username, usertype, hvacusername, emails } =  jose.decodeJwt( mToken);
          if (country) {        
            let newUrl = "";
            let appIdParam = "";
            //console.log(appConfigData.appClientId);
            if(appConfigData.appClientId){
              appIdParam = `&appClientId=${appConfigData.appClientId}`;
            }
            if(appConfigData.isLatestAuth) {
              newUrl = `${appConfigData.postLogOutUri}?apiUrl=${encodeURIComponent(appConfigData.apiUrl)}&uiUrl=${encodeURIComponent(appConfigData.uiUrl)}&redirectAppUri=${encodeURIComponent(window.location.origin)}${appIdParam}&umtoken=${localStorage.getItem("msal.idtoken")}`;
            } else{
              newUrl = `${appConfigData.postLogOutUri}?umtoken=${localStorage.getItem("msal.idtoken")}`;
            }
            window.location.href = newUrl;
          } 
          else 
          {
            if (JSON.parse(localStorage.getItem('applicationConfig')).postLogOutUri.indexOf("zendesk") !== -1) {
              const { iat } =  jose.decodeJwt(mToken);
              if (diff_minutes(new Date(), EpochToDate(iat)) < 3) {
                const newUrl = `${appConfigData.postLogOutUri}${localStorage.getItem("msal.idtoken")}`;
                window.location.href = newUrl;
              } 
              else {
                updateFormState(() => ({ ...formState, formType: "signIn" }));
              }
            } 
            else {
              if (usertype === "CarrierOkta") {
                updateFormState(() => ({ ...formState, formType: "internalSignUp", given_name: given_name, family_name: family_name, username: username }));
              } else if (usertype === "Google") {
                setUserEmail(emails[0]);
                updateFormState(() => ({ ...formState, formType: "socialSignUp", given_name: given_name, family_name: family_name, username: username }));
              } else {
                updateFormState(() => ({ ...formState, formType: "hvacSignUp", username: hvacusername, 'custom:country': 'USA' }));
              }
            }
          }
          break;
        case "signUp":
          break;
        case "parsingCallbackUrl":
          break;
        case "signIn_failure":
          break;
        case "configured":
          break;
        default:
          setShowSignInForm(false);
          break;
      }
    });
  }

  const getUserToken = async () => {
    let currentToken = await getCurrentSessionIdToke();
    return await currentToken
  };

  const modifyTokenCall= async (appConfigData,currentToken)=>{
    let mToken = null;
    if (JSON.parse(localStorage.getItem('applicationConfig')).postLogOutUri.indexOf("zendesk") !== -1) {
      mToken = modifyTokenZendesk(currentToken);
    }
    else {
      mToken = await modifyToken(appConfigData,currentToken);
    }
    return await mToken
  }

  function EpochToDate(epoch) {
    if (epoch < 10000000000)
      epoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
    var epoch = epoch + (new Date().getTimezoneOffset() * -1); //for timeZone        
    return new Date(epoch);
  }
  function diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const login = async () => {
    const appConfigData = JSON.parse(localStorage.getItem('applicationConfig'));
    const currentUrl = window.location.search;

    try {
      const currentToken = await getUserToken();
      let mToken = await modifyTokenCall(appConfigData,currentToken)
      if (!localStorage.getItem(msalToken)) {
        if (!mToken) {
          updateFormState(() => ({ ...formState, formType: "signIn" }));
        }
      } else {
        let newUrl = "";
        let appIdParam = "";
        if(appConfigData.appClientId){
          appIdParam = `&appClientId=${appConfigData.appClientId}`;
        }
        
        if(appConfigData.isLatestAuth) {
            newUrl = `${appConfigData.postLogOutUri}?apiUrl=${encodeURIComponent(appConfigData.apiUrl)}&uiUrl=${encodeURIComponent(appConfigData.uiUrl)}&redirectAppUri=${encodeURIComponent(window.location.origin)}${appIdParam}&umtoken=${localStorage.getItem("msal.idtoken")}`;
        } else {
          newUrl = `${appConfigData.postLogOutUri}?umtoken=${localStorage.getItem("msal.idtoken")}`;
        }
        
        if (JSON.parse(localStorage.getItem('applicationConfig')).postLogOutUri.indexOf("zendesk") !== -1) {
          if(mToken){
            const { iat } =  jose.decodeJwt(mToken);
            if (diff_minutes(new Date(), EpochToDate(iat)) < 3) {
              newUrl = `${appConfigData.postLogOutUri}${localStorage.getItem("msal.idtoken")}`;
              window.location.href = newUrl;
            }else{
              displaySignIn();
            }
          }else{
            displaySignIn();
          }
        } else {
          const { country, given_name, family_name, username, usertype, hvacusername, emails } =  jose.decodeJwt(localStorage.getItem("msal.idtoken"));
          if (country) {
            //console.log("LoginMethod----->", newUrl);
            window.location.href = newUrl;
          }
          else {
            if (usertype === "CarrierOkta") {
              updateFormState(() => ({ ...formState, formType: "internalSignUp", given_name: given_name, family_name: family_name, username: username }));
            } else if (usertype === "Google") {
              setUserEmail(emails[0]);
              updateFormState(() => ({ ...formState, formType: "socialSignUp", given_name: given_name, family_name: family_name, username: username }));
            } else {
              updateFormState(() => ({ ...formState, formType: "hvacSignUp", username: hvacusername, 'custom:country': 'USA' }));
            }
          }
        }
      }
      // code to redirect or show signIn page
    } catch(error) {
      console.log(error);      
      if(new URLSearchParams(currentUrl).get('error_description') == "User is not enabled"){
        setSignInStatus("Your account is disabled. Please ask your Supervisor to invite you again.");
      }
      
      displaySignIn();
      // Code to handle Hvac user .  Hvac user logic has Oid. Please confirm
    }
  };

  const displaySignIn = () => {
    updateFormState(() => ({ ...formState, formType: "signIn" }));
  }

  const onChange = (e) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  const onCheck = (e) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.checked }));
  }

  const onCancel=()=>{
    clearState();
    updateFormState(() => ({ ...formState, username: "",password: "" }));
  }

  const { formType } = formState;

  const signIn = async () => {
    const { username, password } = formState;
    try {
      setSignInUnReq("");
      setSignInPwdReq("");
      setSignInError("");
      setSignInStatus("");

      let isFormValid = true;
      if (username == undefined || username.trim() === "") {
        setSignInUnReq("Please enter your email");
        isFormValid = false;
      } else {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(username)) {
          setSignInUnReq("Please enter a valid email address");
          isFormValid = false;
        }
      }

      if (password == undefined || password.trim() === "") {
        setSignInPwdReq("Please enter your password");
        isFormValid = false;
      }

      if (isFormValid) {
        setSignInStatus("Please wait while validating...");

        try {
          const user = await Auth.signIn({ username, password});
          updateFormState(() => ({ ...formState, formType: "loading" }));
          setSignInStatus("");
        }catch(error){
          if ((error.code.indexOf("NotAuthorizedException") !== -1 || error.message.indexOf("Incorrect") !== -1) 
              && (error.message.indexOf("disabled") == -1)) {
            console.log(error.code);
            await UnlockAccount(username, '', false,appConfig.appType,  async function(response) {
              setSignInStatus("");
              if(response != null && response.noOfAttempts < 5){
                setSignInError(`Your credentials is incorrect. You have ${noOfAttempts - response.noOfAttempts} attempts left.`);
              }else if(response == null ||  response.toString().indexOf("authorized") !== -1){
                setSignInError("Error occurred while processing request.");
              }else {
                setSignInError("Account locked due to too many failed attempts. Please check your email for further instructions.");
              }
            });            
          } else if (error.message.indexOf("instructions") !== -1) {
            setSignInStatus("");
            setSignInError("Account locked due to too many failed attempts. Please check your email for further instructions");
          } else if (error.message.indexOf("disabled") !== -1) {
            setSignInStatus("");
            setSignInError("Your account is disabled. Please ask your Supervisor to invite you again.");
          } else if (error.message.indexOf("exceeded") !== -1) {
            setSignInStatus("");
            setSignInError("Signin attempts exceeded. Please try after some time.");
          } else if (error.message.indexOf("User does not exist") !== -1) {
            try {
              const user = await Auth.signIn({ username, password });
            } catch (e) {
              setSignInStatus("");
              if (error.code.indexOf("NotAuthorizedException") !== -1) {
                setSignInError("Your password is incorrect");
              } else if (error.code.indexOf("UserNotFoundException") !== -1) {
                setSignInError("We can't seem to find your account");
              }
            }
          } else if (error.code.indexOf("UserNotFoundException") !== -1) {
            setSignInStatus("");
            if (error.message.indexOf("Bad password") !== -1){
              if(appConfig.appType === AppType.CSS){
                setSignInError("We can't seem to find your account. Please contact the administrator");
              }else{
                setSignInError("Your email or password is incorrect");
              }
            } else {
                setSignInError("We can't seem to find your account");
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const customSignUp = async () => {
    const appConfigData = JSON.parse(localStorage.getItem('applicationConfig'));
    setInternalSignUpStatus("Please wait while we process your information...");
    const { 'custom:country': country,'custom:state': state,'custom:city': city, username } = formState;
    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
    const result = await Auth.updateUserAttributes(user, {
      'custom:country': country,
      'custom:state': state,
      'custom:city': city
    });

    let currentToken = await getUserToken();
    let mToken = await modifyTokenCall(appConfigData,currentToken)

    localStorage.setItem(msalToken, mToken);
    
    setInternalSignUpStatus("");

    let newUrl = "";
    if(appConfigData.isLatestAuth) {
      newUrl = `${appConfigData.postLogOutUri}?apiUrl=${encodeURIComponent(appConfigData.apiUrl)}&uiUrl=${encodeURIComponent(appConfigData.uiUrl)}&redirectAppUri=${encodeURIComponent(window.location.origin)}&umtoken=${localStorage.getItem("msal.idtoken")}`;
      }
    else{
      newUrl = `${appConfigData.postLogOutUri}?umtoken=${localStorage.getItem("msal.idtoken")}`;
  }
    if (JSON.parse(localStorage.getItem('applicationConfig')).postLogOutUri.indexOf("zendesk") !== -1) {
      newUrl = `${appConfigData.postLogOutUri}${localStorage.getItem("msal.idtoken")}`;
    }
    //console.log("customSignUp ----->", newUrl)
    window.location.href = newUrl;
  }

  const NotificationForgotPassword = async () => {
    setForgotPassword("");
    setForgotPwdStatus("Please wait while processing...");

    const { email } = formState;
    try {
      await ForgotPasswordApiCall(appConfig, {"email": email, "userType": 1,"appType":appConfig.appType,"apiUrl":appConfig.apiUrl,"uiUrl":appConfig.uiUrl,"appClientId":appConfig.appClientId}, function(response) {
        setForgotPwdStatus("");
        if(response){          
          window.location.href = `${appConfig.postLogOutUri || window.location.origin}${getUrlWithoutSlash(appConfig.postLogOutUri)}`;
        }
        else{
          setForgotPwdStatus("Forgot password is unsuccessful. Please verify email is correct.");
        }
      });           
    }
    catch (error) {
      setForgotPwdStatus("No account exists for the provided email.");
      setForgotPassword("");
      console.log(error);
    }
  }

  const getUrlWithoutSlash = (url) => {
    if (!url) return '/';
    if (url[(url.length) - 1] === '/') return '';
    return '/'
  }

  const createGuid = () => {
    let S4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    let guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
    return guid.toLowerCase();
  }

  const signUp = async () => {
    setInternalSignUpStatus("Please wait while we process your information...");
    updateFormState(() => ({ ...formState, username: userEmail }));
    const { password, given_name, family_name, 'custom:country': country, 'custom:state': state, 'custom:city': city } = formState;
    
    //Verify user already exists
    await IsUserExists(userEmail, "1", async function(response) {
      if(response == null || response == undefined){
        const userId = createGuid();
        await Auth.signUp({ 'username': userId, password, attributes: { 'email': userEmail, given_name, family_name,
                          'custom:country': country, 'custom:state': state, 'custom:city': city } })
        updateFormState(() => ({ ...formState, formType: "loading" }));
        setInternalSignUpStatus("Success!! Logging in to CarrierAppsHub...");
        await Auth.signIn(userId, password);  
      }else{
        setInternalSignUpStatus("User is already Signed up. Please do Cancel and SignIn.");
      }
    });
  }

  // const confirmPassword = async () => {
  //   const { username, confirmatinCode, password } = formState;
  //   await Auth.forgotPasswordSubmit(username, confirmatinCode, password);
  //   const newUrl = `${appConfig.postLogOutUri || window.location.origin}${getUrlWithoutSlash(appConfig.postLogOutUri)}`;
  //   //console.log("confirmPassword  ---->", newUrl)
  //   window.location.href = newUrl;
  // }

  const confirmPassword = async () => {
    setForgotPwdStatus("Please wait while processing...");
    const { username, confirmatinCode, password } = formState;
    try{
      await ForgotPasswordApiCall(appConfig, {"email": username, "userType": 1,"password":password,"verificationCode":confirmatinCode}, function(response) {  
        const newUrl = `${appConfig.postLogOutUri || window.location.origin}${getUrlWithoutSlash(appConfig.postLogOutUri)}`;
        window.location.href = newUrl;
      });
    }
    catch (error) {
      setForgotPwdStatus("Error occurred. Please try again.");
      console.log(error);
    }
  }

  const confirmSignUp = async () => {
    const { username, authCode } = formState;
    await Auth.confirmSignUp(username, authCode);
    updateFormState(() => ({ ...formState, formType: "signIn" }));
  }

  const CarrierSignIn = async () => {
    try {
      setShowSignInForm(false);
      const user = await Auth.federatedSignIn({ provider: "CarrierOkta" });
      updateFormState(() => ({ ...formState, formType: "loading" }));
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  const SocialSignIn = async (idpName) => {
    try {
      setShowSignInForm(false);
      const user = await Auth.federatedSignIn({ provider: idpName });
      updateFormState(() => ({ ...formState, formType: "loading" }));
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  const HvacSignIn = async () => {
    setShowSignInForm(false);
    await Auth.federatedSignIn({ provider: envConfig.awsHVACIDP });
  }

  const ForgotPassword = async () => {
    clearState();
    updateFormState(() => ({ ...formState, formType: "forgotPassword" }));
  }

  const OnForgotPasswordCancel=()=>{
    clearState();
    updateFormState(() => ({ ...formState, formType: "signIn" }));
  }

  function clearState(){
    setSignInUnReq("");
    setSignInPwdReq("");
    setSignInError("");
    setForgotPwdStatus("");
  }
  
  return (
    <div className={classes.root}>
      {formType === "signIn" && (
        (appConfig.appType === AppType.CSS) ?
          <CSSSignInForm CarrierSignIn={CarrierSignIn} signInStatus={signInStatus} signInError={signInError} signInUnReq={signInUnReq} signInPwdReq={signInPwdReq}
            ForgotPassword={ForgotPassword} HvacSignIn={HvacSignIn} signIn={signIn} onChange={onChange} />
          :
          <NewSignInForm customTemplate={customTemplate} CarrierSignIn={CarrierSignIn} signInStatus={signInStatus} signInError={signInError} signInUnReq={signInUnReq} signInPwdReq={signInPwdReq}
            ForgotPassword={ForgotPassword} HvacSignIn={HvacSignIn} signIn={signIn} SocialSignIn={SocialSignIn} onChange={onChange} appConfig={appConfig} onCancel={onCancel} />
      )}
      {formType === "loading" && (!localStorage.getItem(msalToken)) && (
        <center>
            <span className={classes.spanStyle}>
                Loading...
            </span>
        </center>
      )}
      {formType === "internalSignUp" && appConfig.appType !== AppType.CSS && (
        <div>
          <NewInternalSignUpForm customTemplate={customTemplate} formState={formState} appConfig={appConfig} internalSignUpStatus={internalSignUpStatus} internalSignUp={customSignUp} onChange={onChange} />
        </div>
      )}
       {formType === "internalSignUp" && appConfig.appType === AppType.CSS && (
        <div>
          <InternalSignUpForm formState={formState} appConfig={appConfig} internalSignUpStatus={internalSignUpStatus} internalSignUp={customSignUp} onChange={onChange} />
        </div>
      )}
      {formType === "hvacSignUp" &&  (
        <div>
          <NewHvacSignUpForm customTemplate={customTemplate} formState={formState} appConfig={appConfig} hvacSignUp={customSignUp} onChange={onChange} />
        </div>
      )}
      {formType === "signUp" && appConfig.appType !== AppType.CSS && (
        <NewSignUpForm userEmail={userEmail} customTemplate={customTemplate} signUp={signUp} appConfig={appConfig} formState={formState} onChange={onChange} onCheck={onCheck} internalSignUpStatus={internalSignUpStatus} />
      )}      
      {formType === "socialSignUp" && appConfig.appType !== AppType.CSS && (
        <NewSocialSignUpForm userEmail={userEmail} customTemplate={customTemplate} signUp={customSignUp} appConfig={appConfig} formState={formState} onChange={onChange} onCheck={onCheck} internalSignUpStatus={internalSignUpStatus} />
      )}
      {formType === "signUp" && appConfig.appType === AppType.CSS && (
        <SignUpForm userEmail={userEmail} signUp={signUp} appConfig={appConfig} formState={formState} onChange={onChange} onCheck={onCheck} internalSignUpStatus={internalSignUpStatus} />
      )}
      {
       formType === "forgotPassword" && appConfig.appType === AppType.CSS && (
        <ForgotPasswordForm NotificationForgotPassword={NotificationForgotPassword} forgotPassword={forgotPassword} forgotPwdStatus={forgotPwdStatus} appConfig={appConfig} formState={formState} onChange={onChange} />
      )}
      {
       formType === "forgotPassword" && appConfig.appType !== AppType.CSS && (
        <NewForgotPasswordForm customTemplate={customTemplate} NotificationForgotPassword={NotificationForgotPassword} forgotPassword={forgotPassword} forgotPwdStatus={forgotPwdStatus} appConfig={appConfig} formState={formState} onChange={onChange} cancel={OnForgotPasswordCancel} />
      )}
      {formType === "resetPassword" && appConfig.appType === AppType.CSS &&(
       <ResetPasswordForm confirmPassword={confirmPassword} appConfig={appConfig} formState={formState} onChange={onChange} />
      )}
      {formType === "resetPassword" &&  appConfig.appType !== AppType.CSS && (
        <NewResetPasswordForm customTemplate={customTemplate} resetPwdStatus={forgotPwdStatus} confirmPassword={confirmPassword} appConfig={appConfig} formState={formState} onChange={onChange} />
      )}
      {formType === "confirmSignUp" && (
        <div>
          <input
            name="authCode"
            type="password"
            onChange={onChange}
            placeholder="Confirmation"
          />
          <button onClick={confirmSignUp}>Confirm Sign Up</button>
        </div>
      )}
    </div>
  );
}

export default LoginWrapper;
