import { Auth } from "aws-amplify";
import { msalToken, clearUMCache,GetUnifyId,GETUNIFYIDSIGNEDTOKEN,encryptData} from "../js/utilities";
// import jwt from "jsonwebtoken";
import {AppType} from "../constant/appType";
import authConfig from "../environment/config";
import axios from 'axios';
//import {GETUNIFYID} from "../js/utilities";
import { async } from "q";
import * as jose from "jose";
var uuid = require('uuid');

// const { sign, decode } = jwt;
let refreshInterval;


export const logout = (appConfig) => {
  var applicationConfig = localStorage.getItem('applicationConfig');
  var userType = "";
  if(appConfig.appType === AppType.CSS){
    userType = localStorage.getItem('usertype');
  }
  localStorage.clear();
  //clearUMCache();

  localStorage.setItem('applicationConfig', applicationConfig);
  if(appConfig.appType === AppType.CSS && userType != ""){
    localStorage.setItem('usertype', userType);
  }

  //let postLogoutURI = `${appConfig.postLogOutUri || window.location.origin}?appConfig=true`
  let postLogoutURI = window.location.protocol + "//" + window.location.host + "?appConfig=true";
  //console.log("postLogoutURILogout------>", postLogoutURI);
  Object.keys(appConfig).forEach(key => {
    postLogoutURI = postLogoutURI + `&${key}=${encodeURIComponent(appConfig[key])}`
  });

  try {
    clearInterval(refreshInterval);
    Auth.signOut();
    window.history.pushState({}, null, removeURLParameter(window.location.href, "isLogout"));
    //window.location.href = postLogoutURI;
  } catch (error) {
    console.log(error);
    clearInterval(refreshInterval);
    //window.location.href = postLogoutURI;
    window.history.pushState({}, null, removeURLParameter(postLogoutURI, "isLogout"));
  }
};

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export const getCurrentSessionIdToke = async () => {
  try {
    let currentUserSession= await getCurrentUserSessionFromCognito()
    let idToken =  currentUserSession.getIdToken();
    let currentIdToken = idToken.getJwtToken();
    return await currentIdToken
  } catch (e) {
    console.log("Unable to fetch Token: "+ e);
    throw e;
  }
};

const getCurrentUserSessionFromCognito= async()=>{
  const currentUser = await Auth.currentSession();
  return await currentUser;
}

// export const refreshSession = async (appConfig) => {
//   try {
//     const cognitoUser = await Auth.currentAuthenticatedUser();
//     const currentSession = await Auth.currentSession();

//     cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
//       if (!err) {
//         const { idToken } = session;
//         let mToken = modifyToken(idToken.getJwtToken());
//         localStorage.setItem(msalToken, mToken);
//         const { exp } = decode(mToken);
//       } else {
//         console.log("Error while refreshing token: " + err);
//       }
//     });
//   } catch (e) {
//     clearInterval(refreshInterval);
//   }
//   finally {
//     redirectToURI(appConfig.postLogOutUri)
//   }
// };

export const redirectToURI = (redirectURI) => {
  const newUrl = `${redirectURI}?umtoken=${localStorage.getItem(msalToken)}`;
  //console.log("redirectToURI ------> ", newUrl)
  window.location.href = newUrl;
}

// export const manageSession = () => {
//   const token = localStorage.getItem(msalToken);
//   const { exp } = decode(token);
//   const expirationTime = exp * 1000;
//   const duration = expirationTime - Date.now();
//   refreshInterval = setInterval(refreshSession, duration);
// };

export const modifyToken = async (appConfigData, token) => {
  try {
    let payload = jose.decodeJwt(token)
    let signedToken;
    let userType = payload.identities && payload.identities.length > 0
        ? payload.identities[0].providerName : "external";
    let hvacUserName = payload.identities && payload.identities.length > 0
        ? payload.identities[0].userId : "";
    const baseApi = (appConfigData.apiUrl && appConfigData.apiUrl.indexOf("qa") !== -1)?  authConfig.api.qa : authConfig.api.development;
    
    let updatedPayload = {
      exp: payload.exp,
      nbf: payload.iat,
      ver: "2", 
      iss:payload.iss, 
      sub: payload.sub,
      aud: payload.aud, 
      nonce: payload.nonce,
      iat: payload.iat,
      auth_time: payload.auth_time,
      idp: payload.idp || payload.iss,
      given_name: payload.given_name,
      family_name: payload.family_name,
      name: payload.family_name,
      oid : payload.sub, 
      country: payload["custom:country"], 
      emails: [payload.email],
      usertype: userType,
      username: payload["cognito:username"] || "",
      hvacusername: hvacUserName,
      state: payload["custom:state"],
      city: payload["custom:city"],
      privileges:"",
      app_privileges:"",
      app_url:encryptData(appConfigData.uiUrl),
      userId:0,
      isViewOnlyAdmin:false
    };  
    
    const updatedSignedToken = await axios.post(`${baseApi}${GETUNIFYIDSIGNEDTOKEN}`, updatedPayload,
    {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Carrierappshub@202!',
    }
    }).then(async(res) => {
      if(res.data.length>0){
        return res.data;
      }
    })
    .catch(error => {
      console.error('Error occurred', error);
    })
    signedToken = updatedSignedToken;
    console.log(signedToken)
    return await signedToken;
  } catch (e) {
    console.log("Unable to modify Token: " + e);
  }
};

const signJwt = async (updatedPayload, secret) => {
  return new jose.SignJWT(updatedPayload)
    .setProtectedHeader({ alg: "HS256" })
    .sign(new TextEncoder().encode(secret));
};


export const modifyTokenZendesk = async (token) => {
  try {
   // let decoded = decode(token, { complete: true });
   let decoded =jose.decodeJwt(token)
    let  payload  = decoded;
    let updatedPayload = {
      iat: payload.iat,
      name: payload.given_name + " " + payload.family_name,
      email: payload.email,
      jti: window.btoa(unescape(encodeURIComponent(uuid.v4())))
    };
    const signedToken =  await signJwt(updatedPayload, "mlJWcK9ZXLUD861AlatRcgAnM9pZmicVQAPwqbfOIJbosuTW");
    return signedToken;
  } catch (e) {
    console.log("Unable to modify Token: " + e);
  }
};
