const appConfig = {
  awsProjectRegion: "us-east-2",
  awsCognitoIdentityPoolId: "us-east-2:b6b39200-97b9-4906-9372-a11d3ff9fa44",
  awsCognitoRegion: "us-east-2",
  awsUserPoolsId: "us-east-2_s5NUFfIbw",
  awsUserPoolsWebClientId: "58pd4ncdvqki5cmjh6oaekhri9",
  awsCognitoDomain: "carrierappshub-up.auth.us-east-2.amazoncognito.com",
  awsHVACIDP: "hvacqaextcarrier",
  storageUri: 'https://d1oqom3ssbr3mh.cloudfront.net/',
  api:{
    development: 'https://devapi.carrierappshub1.com',
    qa: 'https://qaapi.carrierappshub1.com'
  },
  ui:{
    development: 'https://dev.carrierappshub1.com',
    qa: 'https://qa.carrierappshub1.com'
  }
};

export default appConfig;